//
// Theme style
//

// Mode
$mode: default;
 
// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "layout/layout";
@import "./core/layout/docs/layout";

/* .list {
    width: 100%;
    display: flex;
    height: auto;
    margin: auto;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .list__card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
    padding: 15px;
    box-shadow: 0 0.7rem 1.2rem rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .list_card_image {
    width: 200px;
    height: 200px;
    overflow: hidden;
  }
  .list__card-right {
    margin: 2rem 0;
  } */


  .image_node {
    position: relative;
  }
  .image_node div{
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    background: #f1416c;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .image_node i.fa.fa-trash{
    color: #fff;
    font-size: 16px;
    padding-right: 0;
  }
  .image_node button{
    color: #fff;
    font-size: 16px;
  }

  input[type="date"] {
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.list_card_image img{
  max-height: 210px;
}
.list__card > div{
  width: 100%;
}

.flex-column-fluid #kt_content_container.container{
  width: 100%;
  max-width: none;
}

td div.text-end{
  white-space: nowrap;
}